import React from "react";
import Structurelayout from "../../../components/structureLayout/structurelayout";
import Button from "../../../components/button/button";
import { useAppContext } from "../../../context/app.context";
import { useNavigate } from "react-router-dom";

export default function IbanPage() {
  const { appInfos } = useAppContext();
  const navigate = useNavigate();
  return (
    <Structurelayout header={Header()}>
      <div className="row">
        <div className="col">
          <h3 style={{ marginBottom: 15 }}>Mon IBAN</h3>
        </div>
        <div className="col-auto">
          <Button
            className="btn btn-secondary btnpad40 btnsmall"
            onClick={() => navigate("/structure/iban/add")}
            title={!appInfos.user.iban ? "Ajouter un IBAN" : "Modifier mon iban"}
          />
        </div>
      </div>
      {appInfos.user.iban && (
        <div className="graycard" style={{ padding: 32, borderRadius: 8, marginTop: 15 }}>
          <div className="row">
            <div className="col">
              <span className="currentM">{appInfos.user?.ibanInfos?.name}</span>
            </div>
            <div className="col-auto">
              <span className="currentR">
                {appInfos.user?.ibanInfos?.iban?.slice(0, 4) +
                  " " +
                  appInfos.user?.ibanInfos?.iban?.slice(4, 8) +
                  " **** **** ***"}
              </span>
            </div>
          </div>
        </div>
      )}
    </Structurelayout>
  );
}

function Header() {
  return (
    <div>
      <h1 className="headertitle">Mon profil</h1>
    </div>
  );
}
