/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import TextInput, { TextInputType } from "../text-input/text-input";
import { useController, useFormContext, UseControllerReturn, FieldValues } from "react-hook-form";
import axiosInstance from "../../../../config/axios";
import * as _ from "lodash";

// Update the AutoCompleteInput function to make onSelectItem optional
const AutoCompleteInput = AutoCompleteInputController((props: AutoCompleteInputType) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.value?.address) {
      props.controller?.field?.onChange(props?.value);
    }
  }, [props.value]);

  const search = _.debounce(async (value: string) => {
    if (!value) {
      setData([]);
      return;
    }
    try {
      const { data } = await axiosInstance.get(`${props.url}?search=${value}`);
      setData(data.data.map((elt: any) => ({ label: `${elt.first_name} ${elt.last_name}`, value: elt })));
      return;
    } catch (e) {
      if (props.onData) props.onData([]);
    }
  }, 300);

  async function onSelectItem(item: any) {
    try {
      // Call onSelectItem if it's provided, otherwise do nothing
      if (props.onSelectItem) {
        props.onSelectItem(item.value._id); // Send user ID
      }
      props.controller?.field?.onChange(item.value);
    } catch (e) {
      console.log(e);
    }
  }

  const error = props.controller?.fieldState?.invalid;
  const errorMSg = props.controller?.fieldState?.error?.message;
  const success = props.controller?.fieldState?.isDirty && !error;

  return (
    <TextInput
      onBlur={props.controller?.field?.onBlur}
      {...props}
      controller={undefined}
      name={undefined}
      onSelectItem={onSelectItem}
      datalist={data}
      errorMsg={errorMSg}
      className={`${error ? "form-control-invalid" : ""} ${success ? "form-control-valid" : ""} ${props.className}`}
      onChange={search}
    />
  );
});

export default AutoCompleteInput;

function AutoCompleteInputController(Input: any) {
  return function (props: AutoCompleteInputType) {
    return InputControl(Input, props);
  };
}

function InputControl(Input: any, p: AutoCompleteInputType) {
  const formContext = useFormContext();
  let controller: any;
  if (p.name && formContext)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    controller = useController({
      name: p.name,
      rules: {
        validate: (val: any) => {
          if (p.required) {
            return val?._id ? true : p.required;
          }
          return !val || val?._id ? true : p.errorMsg;
        },
      },
      defaultValue: p?.value,
    });
  return <Input {...p} controller={controller} />;
}

// Define the types, including the optional userId prop
interface AutoCompleteInputType extends TextInputType {
  language?: string;
  countries?: string[];
  onData?: Function;
  controller?: UseControllerReturn<FieldValues, string>;
  onChooseAddress?: Function;
  showlocationBtn?: boolean;
  value?: any;
  url?: string;
  onSelectItem?: (userId: string) => void; // Optional prop for returning the user ID
}
