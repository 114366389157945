import React, { useEffect, useState } from "react";
import axiosInstance from "../../../config/axios";
import Structurelayout from "../../../components/structureLayout/structurelayout";
import AddTarifPopup from "../../../components/popups/AddTarifPopup/AddTarifPopup";
import List from "../../../components/lists/list";
import { formatPrice } from "../../../utils/utils";
import Modal from "../../../components/modal/modal";
import Button from "../../../components/button/button";

// Define a comprehensive TarifData interface
interface TarifData {
  _id: string;
  user: string;
  title: string;
  description: string;
  category: string;
  ht: number;
  tva: number; // Consider making this dynamic in your UI
  ttc: number;
  createdAt: string;
  updatedAt: string;
}

// Create a type for table display that matches the table's expectation
type TableTarifData = Pick<TarifData, "_id" | "title" | "description" | "ht" | "tva" | "ttc" | "category">;

export default function TarifsPage() {
  const [data, setData] = useState<TarifData[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
  const [editingTarif, setEditingTarif] = useState<TarifData | undefined>(undefined);
  const [loading, setloading] = useState(false);
  const [opendelete, setopendelete] = useState(false);
  const [selecetdid, setselectedid] = useState("");
  const fetchTarifs = async () => {
    setloading(true);
    try {
      const response = await axiosInstance.get("/tarifs");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching tarifs:", error);
    }
    setloading(false);
  };
  useEffect(() => {
    fetchTarifs();
  }, []);

  const handleDeleteTarif = async (id: string) => {
    if (!id) {
      console.error("Error: Tarif ID is undefined.");
      return;
    }

    try {
      await axiosInstance.delete(`/tarifs/${id}`);
      setData((prevData) => prevData.filter((tarif) => tarif._id !== id));
    } catch (error) {}
  };

  const handleAddTarif = (category: string) => {
    setSelectedCategory(category);
    setEditingTarif(undefined);
    setIsPopupOpen(true);
  };

  const handleEditTarif = (tarif: TableTarifData) => {
    const fullTarifData = data.find((t) => t._id === tarif._id);
    if (fullTarifData) {
      setSelectedCategory(fullTarifData.category);
      setEditingTarif(fullTarifData);
      setIsPopupOpen(true);
    }
  };

  const handleSaveTarif = async (newTarif: Omit<TarifData, "_id" | "createdAt" | "updatedAt">, category: string) => {
    try {
      if (editingTarif) {
        const response = await axiosInstance.post(`/tarifs/${editingTarif._id}`, { ...newTarif, category });
        setData((prevData) => prevData.map((tarif) => (tarif._id === editingTarif._id ? response.data : tarif)));
      } else {
        const response = await axiosInstance.post("/tarifs", {
          ...newTarif,
          category,
        });
        setData((prevData) => [...prevData, response.data]);
      }
      fetchTarifs();
    } catch (error) {
      console.error("Error saving tarif:", error);
    }
  };
  function onAdd() {
    setIsPopupOpen(false);
    fetchTarifs();
  }

  const tarifColumns: any = [
    {
      name: "title",
      cellClass: "currentM tleft",
      flex: 1.5,
    },
    {
      name: "ttc",
      cellClass: "currentS tcenter ",
      fnValue: (data: any) => formatPrice(data.ttc) + " €",
      flex: 1.3,
    },
    {
      name: "description",
      cellClass: "currentS tleft",
      cell: (col: any) => (
        <span style={{ marginLeft: 40 }} className="currentS">
          {col.description}
        </span>
      ),
      flex: 2,
      noDivider: true,
    },
    {
      name: "title",
      cellClass: "currentS tleft",
      flex: 0.5,
      cell: (val: any) => (
        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
          <div style={{cursor:'pointer'}} onClick={() => handleEditTarif(val)}>
            <span className="action">
              <img src={"/images/edit.svg"} alt="modifier" />
            </span>
          </div>
          <div
            onClick={() => {
              setselectedid(val._id);
              setopendelete(true);
            }}
            style={{ cursor: "pointer" }}
          >
            <span className="action">
              <img style={{ marginLeft: 10 }} src="/images/remove.svg" alt="supprimer" />
            </span>
          </div>
        </div>
      ),
    },
  ];
  return (
    <Structurelayout loading={loading} header={<Header onAdd={() => handleAddTarif("")} />}>
      {["Administratif", "Cours", "Pension", "Autres"].map((category, ind: any) => (
        <div style={{ marginBottom: 20 }} key={ind} className="row">
          <List
            onBtnAction={() => handleAddTarif(category)}
            btnTitle="Ajouter un tarif"
            title={category}
            data={data.filter((elt: any) => elt.category == category)}
            columns={tarifColumns}
          />
        </div>
      ))}
      <Modal
        title="Supprimer un tarif"
        description="Êtes-vous sûr de vouloir supprimer ce tarif ?"
        onConfirm={async () => {
          await handleDeleteTarif(selecetdid);
          setopendelete(false);
          setselectedid("");
        }}
        onClose={() => {
          setopendelete(false);
          setselectedid("");
        }}
        show={opendelete}
      />

      {isPopupOpen ? (
        <AddTarifPopup
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
            setSelectedCategory(undefined);
            setEditingTarif(undefined);
          }}
          selectedCategory={selectedCategory}
          initialData={editingTarif}
          onAdd={() => onAdd()}
          onSave={async (newTarif) => {
            if (selectedCategory) {
              await handleSaveTarif({ ...newTarif, category: selectedCategory }, selectedCategory);
            } else {
              console.error("Error: selectedCategory is undefined.");
            }
            setIsPopupOpen(false);
            setSelectedCategory(undefined);
            setEditingTarif(undefined);
          }}
        />
      ) : null}
    </Structurelayout>
  );
}

function Header(props: any) {
  return (
    <div style={{ marginTop: 20, marginBottom: 15 }}>
      <div style={{ padding: 0, margin: 0 }} className="row">
        <div style={{ padding: 0, margin: 0 }} className="col">
          <h1 style={{ marginTop: 0 }} className="headertitle">
            Mes tarifs
          </h1>
        </div>
        <div style={{ padding: 0, margin: 0 }} className="col-auto">
          <div className="actions">
            <Button onClick={props.onAdd} title="Ajouter un tarif" className="btn-download btntarif" />
          </div>
        </div>
      </div>
    </div>
  );
}
