import React, { useEffect, useRef, useState } from "react";
import { Modal as Mmodal } from "react-responsive-modal";
import "./filtermodal.css";
import Button from "../button/button";
import CheckBoxInput from "../form/inputs/check-input/check-input";
import { useAppContext } from "../../context/app.context";
import * as _ from "lodash";
export default function Filtermodal(props: {
  show: boolean;
  description?: any;
  filtername?: any;
  filterOptions?: any;
  filtertitle?: any;
  btnconfirm?: string;
  btnclose?: string;
  onConfirm?: Function;
  onClose?: any;
}) {
  const [open, setOpen] = useState(false);
  const { appInfos, setAppInfos } = useAppContext();
  const [filtersvals, setfiltersvals] = useState(_.cloneDeep(appInfos?.user?.filters));
  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);
  console.log(filtersvals)
  return (
    <Mmodal
      closeIcon={() => null}
      classNames={{
        modal: "filtermodal",
      }}
      open={open}
      onClose={() => {
        setOpen(false);
        if (props.onClose) props.onClose();
      }}
      center
    >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span className="subtitle modaltitle">Filtrer</span>
        <div style={{ cursor: "pointer" }} onClick={props.onClose}>
          <img width={28} src="/images/close.svg" alt="" />
        </div>
      </div>
      <div className="modalcontent">
        <h5 style={{ fontSize: 20, textAlign: "left", marginBottom: 15 }} className="subtitle">
          Par {props.filtertitle}
        </h5>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <span className="currentR">{"Tous"}</span>
          <CheckBoxInput
            value={
              filtersvals &&
                  filtersvals[props.filtername]?.length &&
                  filtersvals[props.filtername]?.length== props.filterOptions?.length
              
            }

            onChange={(value: any) => {
              let filters = _.cloneDeep(filtersvals);
              if (!filters) {
                filters = {};
              }
              if (!filters[props.filtername]) {
                filters[props.filtername] = [];
              }
              if (value) {
                filters[props.filtername] = props.filterOptions.map((elt:any)=>elt.value);
              } else {
                filters[props.filtername] = [];
              }
              setfiltersvals(filters)
            }}
          />
        </div>
        {props.filterOptions?.map((elt: any, ind: any) => {
          return (
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} key={ind}>
              <span className="currentR">{elt.label || elt}</span>
              <CheckBoxInput
                onChange={(value: any) => {
                  let filters = _.cloneDeep(filtersvals);
                  if (!filters) {
                    filters = {};
                  }
                  if (!filters[props.filtername]) {
                    filters[props.filtername] = [];
                  }
                  if (!value) {
                    filters[props.filtername] = filters[props.filtername]?.filter(
                      (e: any) => (e.value || e) != (elt.value || elt),
                    );
                  } else {
                    filters[props.filtername].push(elt.value || elt);
                  }
                  setfiltersvals(filters);
                }}
                value={
                  filtersvals &&
                  filtersvals[props.filtername]?.length &&
                  filtersvals[props.filtername]?.indexOf(elt.value || elt) != -1
                }
              />
            </div>
          );
        })}
        <div style={{ display: "flex", flexGrow: 1, alignItems: "flex-end", width: "100%", marginTop: 7 }}>
          <div style={{ display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "flex-end" }}>
            <Button className="btn-tertiary" onClick={props.onClose} title={props.btnclose || "Annuler"} />
            <div style={{ marginLeft: 20 }}>
              <Button
                className="btn-primary "
                onClick={() => {
                  setAppInfos({ user: { ...appInfos.user, filters: filtersvals } });
                  if (props.onConfirm)
                    props.onConfirm(
                      filtersvals[props.filtername]?.length
                        ? [{ id: props.filtername, value: filtersvals[props.filtername] }]
                        : [],
                    );
                }}
                title={"Appliquer les filtres"}
              />
            </div>
          </div>
        </div>
      </div>
    </Mmodal>
  );
}
