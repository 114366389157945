import React from "react";
import LogoLayout from "../../../components/logolayout/logolayout";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { formatInputPrice, formatPhone, formatSiret, phonereg, showToast, sirenreg } from "../../../utils/utils";
import PlacesInput from "../../../components/form/inputs/places-input/places-inputs";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import axiosInstance from "../../../config/axios";
import SelectInput from "../../../components/form/inputs/select-input/select-input";

export default function RegisterStructurePage() {
  const redirect = window.history.state.idx == 0;
  const location = useLocation();
  const navigate = useNavigate();
  const params = location.state;
  async function onSubmit(form: any) {
    try {
      form = { ...params, capital: form.capital, structure: form };
      const { data } = await axiosInstance.post("users/register/complete", form);
      navigate("/register/success", { state: { form, plans: data.plans } });
      //navigate("/register/subscription", { state: { form, plans: data.plans } });
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }
  if (redirect) return <Navigate to={"/register"} replace />;
  const types = [
    { label: "Ecurie", value: "Ecurie" },
    { label: "Centre équestre", value: "Centre équestre" },
    { label: "Ferme équestre", value: "Ferme équestre" },
    { label: "Haras", value: "Haras" },
    { label: "Elevage", value: "Elevage" },
  ];
  return (
    <LogoLayout onSubmit={onSubmit} progress={50}>
      <h1>Inscription</h1>
      <p className="subtitle">Sructure</p>
      <h2 className="title">La Structure</h2>
      <div>
        <TextInput required='Nom invalide' name="name" placeHolder="Nom structure" label="Nom de la structure" />
        <SelectInput
          placeHolder="Ecurie, Elevage..."
          options={types}
          name="type"
          label="Type de structure"
          required="Il faux sélectionner type structure"
        />
        <TextInput
          validate={(val: any) => (!sirenreg.test(val?.replaceAll(" ", "")) ? "SIRET invalide" : true)}
          maxLength={17}
          name="siret"
          format={formatSiret}
          placeHolder="000 000 000 00000"
          label="SIRET"
        />
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <TextInput
              validate={(val: any) => (val && isNaN(val) ? "Capital social invalide" : true)}
              name="capital"
              format={formatInputPrice}
              rightIcon={<span style={{ fontSize: 16, fontFamily: "Rubik", color: "#81898d" }}>€</span>}
              placeHolder="0 "
              label="Capital social"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <TextInput
              validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
              name="phone"
              placeHolder="06.00.00.00.00"
              format={formatPhone}
              maxLength={14}
              label="Téléphone de la structure"
            />
          </div>
        </div>

        <PlacesInput required placeHolder="Adresse" label="Adresse postale" name="address" />
      </div>
    </LogoLayout>
  );
}
