/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import "./list.css";
import Button from "../button/button";
export default function List(props: {
  data: any[];
  columns: any[];
  title?: string;
  btnTitle?: string;
  category?: string;
  onBtnAction?: any;
  style?: any;
  emptyTitle?: string;
  onClickItem?: Function;
}) {
  function getValue(obj: any, key: any) {
    return key.split(".").reduce((o: any, k: any) => {
      if (o && k in o) {
        return o[k];
      }
      return undefined;
    }, obj);
  }
  return (
    <div style={props.style} className="itemslist graycard">
      <div>
        <div className="head">
          {props.title && <h3>{props.title}</h3>}
          {props.btnTitle ? (
            <Button onClick={props.onBtnAction} className="btn-tertiary" title={props.btnTitle} />
          ) : null}
        </div>
        {props.data?.length ? (
          <ul className="list-group">
            {props.data.map((elt: any, ind) => (
              <li
                style={{
                  marginBottom: ind != props?.data.length - 1 ? 8 : 0,
                  cursor: props.onClickItem ? "pointer" : "unset",
                }}
                onClick={() => {
                  if (props.onClickItem) props.onClickItem(elt);
                }}
                key={ind+""}
                className="list-group-item"
              >
                {props.columns.map((col, ind1) => (
                  <>
                    <div
                      key={ind + "" + ind1}
                      className="listcol"
                      style={{
                        flex: col.flex || 1,
                        maxWidth: col.maxWidth,
                        justifyContent: col.justifyContent,
                      }}
                    >
                      {col.cell ? (
                        col.cell(elt)
                      ) : (
                        <span className={"cellspan " + (col.cellClassFn ? col.cellClassFn(elt) : col.cellClass)}>
                          {col.fnValue ? col.fnValue(elt)?.toString() : getValue(elt, col.name)?.toString()}
                        </span>
                      )}
                    </div>

                    {ind1 != props.columns.length - 1 && (
                      <div
                      key={ind + "divider" + ind1}
                        className="divider"
                        style={{
                          backgroundColor: col.noDivider ? "white" : "#B3C2CA",
                        }}
                      ></div>
                    )}
                  </>
                ))}
              </li>
            ))}
          </ul>
        ) : props.emptyTitle ? (
          <div className="emptydata">
            <span className="currentM">{props.emptyTitle}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
