import React from "react";
import { Route, Routes, BrowserRouter as Router, Outlet, Navigate } from "react-router-dom";
import WelcomePage from "../pages/structure/welcome/welcome.page";
import LoginPage from "../pages/login/login.page";
import Page404 from "../pages/404/404.page";
import RegisterPage from "../pages/register/register.page";
import PasswordResetPage from "../pages/resetpassword/passwordReset.page";
import PasswordCodePage from "../pages/resetpassword/passwordCode.page";
import PasswordConfirmPage from "../pages/resetpassword/passwordConfirm.page";
import PasswordSucessPage from "../pages/resetpassword/passwordSucess.page";
import RegisterProfilPage from "../pages/register/prestataire/registerProfil.page";
import RegisterPrestatairePage from "../pages/register/prestataire/registerPrestataire.page";
import RegisterSubscriptionPage from "../pages/register/registerSubscription.page";
import RegisterSucessPage from "../pages/register/registerSuccess.page";
import RegisterManagerPage from "../pages/register/structure/registerManager.page";
import RegisterStructurePage from "../pages/register/structure/registerStructure.page";
import RegisterCavalierPage from "../pages/register/cavalier/registerCavalier.page";
import RegisterCavalierProfilPage from "../pages/register/cavalier/registerProfil.page";
import RegisterCavalierTuteurPage from "../pages/register/cavalier/registerTuteur.page";
import { useAppContext } from "../context/app.context";
import LandingPage from "../pages/landing/landing.page";
import ConditionsPage from "../pages/conditions/conditions.page";
import MentionsPage from "../pages/mentions/mentions.page";
import CGUPage from "../pages/cgu/cgu.page";
import ClientsPage from "../pages/structure/clients/clients.page";
import TarifsPage from "../pages/structure/tarifs/tarifs.page";
import SubscriptionPage from "../pages/profil/abonnement/subscription.page";
import AccountPage from "../pages/profil/compte/account.page";
import StructurePage from "../pages/profil/structure/structure.page";
import ManagerPage from "../pages/profil/manager/manager.page";
import FacturePage from "../pages/structure/gestion/factures/facture.page";
import ListFacturesPage from "../pages/structure/gestion/factures/listfactures.page";
import ListDocumentPage from "../pages/structure/gestion/documents/listdocument.page";
import IbanPage from "../pages/profil/iban/iban.page";
import AddClient from "../pages/structure/clients/AddClient";
import SingleClient from "../pages/structure/clients/SingleClient";
import AddDocumentPage from "../pages/structure/gestion/documents/adddocument/AddDocumentPage";
import AddIbanPage from "../pages/profil/iban/addiban.page";
import AddFacturePage from "../pages/structure/gestion/factures/addfacture.page";
import EditClient from "../pages/structure/clients/EditClient";

export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<LandingPage />} />
        <Route path="/conditions" element={<ConditionsPage />} />
        <Route path="/mentions" element={<MentionsPage />} />
        <Route path="/cgu" element={<CGUPage />} />
        <Route element={<DisconnectedRoute />} path="/login">
          <Route path="" element={<LoginPage />} />
        </Route>
        <Route element={<DisconnectedRoute />} path="/password">
          <Route path="reset" element={<PasswordResetPage />} />
          <Route path="code" element={<PasswordCodePage />} />
          <Route path="confirm" element={<PasswordConfirmPage />} />
          <Route path="success" element={<PasswordSucessPage />} />
        </Route>
        <Route element={<DisconnectedRoute />} path="/register">
          <Route path="" element={<RegisterPage />} />
          <Route path="cavalier/informations" element={<RegisterCavalierPage />} />
          <Route path="cavalier/profil" element={<RegisterCavalierProfilPage />} />
          <Route path="cavalier/tuteur" element={<RegisterCavalierTuteurPage />} />
          <Route path="structure/informations" element={<RegisterStructurePage />} />
          <Route path="structure/manager" element={<RegisterManagerPage />} />
          <Route path="prestataire/informations" element={<RegisterPrestatairePage />} />
          <Route path="prestataire/profil" element={<RegisterProfilPage />} />
          <Route path="subscription" element={<RegisterSubscriptionPage />} />
          <Route path="success" element={<RegisterSucessPage />} />
        </Route>
        <Route path="/structure" element={<ConnectedRoute userRole="Structure" />}>
          <Route path="" element={<ActiveStatusRoute />}>
            <Route path="" element={<WelcomePage />} />
            <Route path="clients" element={<ClientsPage />} />
            <Route path="clients/add" element={<AddClient />} />
            <Route path="clients/:id" element={<SingleClient />} />
            <Route path="clients/:id/edit" element={<EditClient />} />
            <Route path="tarifs" element={<TarifsPage />} />
            <Route path="gestion">
              <Route path="" element={<Navigate to="factures" />} />
              <Route path="factures">
                <Route path="" element={<ListFacturesPage />} />
                <Route path="add" element={<AddFacturePage />} />
                <Route path=":id" element={<FacturePage />} />
              </Route>
              <Route path="documents">
                <Route path="" element={<ListDocumentPage />} />
                <Route path=":id" element={<AddDocumentPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="iban/add" element={<AddIbanPage />} />
          <Route path="profil">
            {ProfilRoutes()}
            <Route path="manager" element={<ManagerPage />} />
            <Route path="structure" element={<StructurePage />} />
          </Route>
        </Route>
        <Route path="/prestataire" element={<ConnectedRoute userRole="Prestataire" />}>
          <Route path="" element={<Navigate to={"profil/account"} />} />
          <Route path="profil">{ProfilRoutes()}</Route>
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}
function ProfilRoutes() {
  return (
    <>
      <Route path="" element={<Navigate to="account" />} />
      <Route path="account" element={<AccountPage />} />
      <Route path="subscription" element={<SubscriptionPage />} />
      <Route path="iban" element={<IbanPage />} />
    </>
  );
}

export function ActiveStatusRoute() {
  const { appInfos } = useAppContext();
  return appInfos.user.status == "Active" ? <Outlet /> : <Navigate to={"/structure/profil"} />;
}

export function ConnectedRoute({ userRole }: any) {
  const { appInfos } = useAppContext();
  return appInfos.token && appInfos?.user?.role == userRole ? (
    <Outlet />
  ) : appInfos.token ? (
    <Page404 />
  ) : (
    <Navigate to="/login" />
  );
}

export function DisconnectedRoute() {
  const { appInfos } = useAppContext();
  return appInfos.token && appInfos.user.status == "Active" ? (
    <Navigate to={appInfos?.user?.role == "Structure" ? "/structure" : "/prestataire"} />
  ) : (
    <Outlet />
  );
}
