import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import "./structurelayoutLP.css";

export default function StructurelayoutLP(props: any) {
  const router = useLocation();

  function linkStatus(link: any) {
    return router.pathname.indexOf(link) !== -1 ? "-active" : "";
  }

  return (
    <div className="structurelayoutLP">
      <div className="header-container row">
        <div className="header-left col-md-6">
          <header className="header">
            <Navbar expand="lg">
              <Container>
                <Navbar.Brand as={Link} to="/" className="mr-75">
                  <img src="/images/logoPrimary.svg" alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    <Nav.Link className="nav-link-custom" href="#plateforme">
                      Plateforme
                    </Nav.Link>
                    <Nav.Link className="nav-link-custom" href="#fonctionnalites">
                      Fonctionnalités
                    </Nav.Link>
                    {/* <Nav.Link as={Link} to="/tarification" className="nav-link-custom">
                    {/* <Nav.Link href="#tarification" className="nav-link-custom">
                      Tarification
                    </Nav.Link> */}
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </header>
          {props.headerleft}
        </div>
        <div className="header-right col-md-6">{props.headerright}</div>
      </div>
      <div className="content">{props.children}</div>
      <div className="footer">
        <div className="footer-logo">
          <img src="/images/Logo.svg" alt="Logo" />
        </div>
        <div className="footer-links">
          <Link className={`link${linkStatus("/conditions")} me-5`} to="/conditions">
            Politique de confidentialité
          </Link>
          <Link className={`link${linkStatus("/mentions")} me-5`} to="/mentions">
            Mentions légales
          </Link>
          <Link className={`link${linkStatus("/cgu")} me-5`} to="/cgu">
            CGV/CGU
          </Link>
        </div>
        <div className="footer-icons">
          <img src="/images/facebook.svg" alt="Facebook" />
          <img src="/images/linkedin.svg" alt="LinkedIn" />
          <img src="/images/youtube.svg" alt="YouTube" />
          <img src="/images/instagram.svg" alt="Instagram" />
        </div>
      </div>
    </div>
  );
}
