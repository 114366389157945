import React, { useEffect, useState } from "react";
import Structurelayout from "../../../components/structureLayout/structurelayout";
import { useAppContext } from "../../../context/app.context";
import List from "../../../components/lists/list";
import Button from "../../../components/button/button";
import moment from "moment";
import axiosInstance from "../../../config/axios";
import { formatPhone, getFacturePrice, getFactureTagClass, getFactureTagTitle, showToast } from "../../../utils/utils";
import "./welcome.page.css";
import { useNavigate } from "react-router-dom";
export default function WelcomePage() {
  const [loading, setloading] = useState(false);
  const { appInfos } = useAppContext();
  const [lists, setlists]: any = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    getData();
  }, [appInfos.token]);
  async function getData() {
    if(!appInfos.token)
      return
    console.log(appInfos)
    setloading(true);
    try {
      const { data } = await axiosInstance.get("/structure/home");
      setlists(data);
    } catch (e) {
      console.log(e)
      showToast("Une erreur est survenue");
    }
    setloading(false);
  }

  const clientsColumns = [
    {
      name: "Name",
      cellClass: "currentM",
      fnValue: (val: any) => val?.first_name + " " + val?.last_name,
      flex: 2,
    },
    {
      name: "phone",
      cellClass: "currentXS tcenter",
      fnValue: (val: any) => formatPhone(val.phone)?.replaceAll(".", " "),
      flex: 1,
    },
    {
      name: "role",
      cellClass: "currentXS tright",
      flex: 1,
    },
  ];
  const facturesColumns = [
    {
      name: "title",
      cellClass: "currentM tleft",
      flex: 1.8,
    },
    {
      name: "emissionDate",
      cellClass: "currentR tcenter",
      fnValue: (val: any) => moment(val.emissionDate).format("DD/MM/YYYY"),
      flex: 1,
    },
    {
      name: "Name",
      cellClass: "currentR tcenter",
      fnValue: (val: any) => val?.recepteur?.first_name + " " + val?.recepteur?.last_name,
      flex: 1,
      maxWidth: 200,
    },
    {
      name: "priceTTC",
      cellClassFn: (val: any) => "currentR tright " + (val.status == "Refusée" ? "tline" : ""),
      fnValue: (val: any) => getFacturePrice(val, appInfos.user),
      flex: 0.5,
      noDivider: true,
    },
    {
      name: "status",
      justifyContent: "flex-end",
      cellClassFn: getFactureTagClass,
      fnValue: getFactureTagTitle,
      flex: 0.5,
    },
  ];
  return (
    <Structurelayout loading={loading} header={Header()}>
      <div className="welcome">
        <div className="row">
          <List
            style={{minHeight:!lists?.lastFacture?.length ? 339:undefined}}
            title="Les dernières factures"
            data={lists?.lastFacture}
            onClickItem={(val: any) => {
              navigate("/structure/gestion/factures/" + val._id);
            }}
            btnTitle="Voir tous les documents"
            columns={facturesColumns}
            emptyTitle="Vous n’avez pas encore de factures."
            onBtnAction={() => navigate("gestion")}
          />
        </div>
        <div className="row  gy-3 lastclients">
          <div style={{ padding: 0 }} className="col ">
            <List
              style={{ minHeight: !lists?.lastClients?.length ? 227 : undefined }}
              title="Les derniers clients"
              data={lists?.lastClients}
              onClickItem={(val: any) => {
                navigate("clients/" + val._id);
              }}
              emptyTitle="Vous n’avez pas encore de fiche client."
              btnTitle="Voir tous les clients"
              columns={clientsColumns}
              onBtnAction={() => navigate("clients")}
            />
          </div>
          <div style={{ padding: 0}} className="col-auto tarifcontainer">
            <div className="tarifs">
              <div style={{ marginLeft: 20 }}>
                <Button onClick={() => navigate("tarifs")} className="btn-tertiary" title="Voir tous les tarifs" />
              </div>
              <img className="tariflogo" alt="" src="/images/tariflogo.svg" />
            </div>
          </div>
        </div>
      </div>
    </Structurelayout>
  );
}
function Header() {
  const { appInfos } = useAppContext();
  return (
    <div className="mb-3">
      <h1 className="headertitle">Bienvenue dans votre espace</h1>
     <div style={{width:'100%',overflow:'hidden',textOverflow:'ellipsis'}}>
      <span className="subtitle">{appInfos?.user?.structure?.name}</span>
      </div> 
    </div>
  );
}
