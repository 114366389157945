import React, { useEffect, useState } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import axiosInstance from "../../../config/axios";
import TextInput from "../../form/inputs/text-input/text-input";
import SelectInput from "../../form/inputs/select-input/select-input";
import Button from "../../button/button";
import "./AddTarifPopup.css";
import { formatNumber, formatPrice, showToast } from "../../../utils/utils";

interface AddTarifPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSave?: (data: Omit<TarifData, "_id" | "createdAt" | "updatedAt">) => Promise<void>;
  onAdd?: any;
  selectedCategory?: string;
  initialData?: TarifData;
}

interface FormData {
  tarif: string;
  montant_ht: string;
  tva: string;
  description: string;
  category: string;
}

interface TarifData {
  _id: string;
  user: string;
  title: string;
  description: string;
  category?: string;
  ht: number;
  tva: number;
  ttc: number;
  createdAt: string;
  updatedAt: string;
}

const AddTarifPopup: React.FC<AddTarifPopupProps> = ({
  isOpen,
  onClose,
  onSave,
  onAdd,
  selectedCategory,
  initialData,
}) => {
  const [ttc, setTtc] = useState<number>(0);

  const methods = useForm({ mode: "onBlur" });

  const { handleSubmit, watch, reset } = methods;

  const montantHT = watch("montant_ht");

  const [tva, settva]: any = useState(initialData?.tva + "");

  console.log("ùùùùùùùù", tva,initialData?.tva + "");
  useEffect(() => {
    const htValue = parseFloat(montantHT || "0");
    const tvaValue = parseFloat(tva?.value != undefined ? tva.value : tva);
    setTtc(!tva ? htValue : htValue * (1 + tvaValue / 100));
  }, [montantHT, tva?.value, tva]);

  useEffect(() => {
    if (initialData) {
    }
  }, [initialData, reset, selectedCategory]);

  const onSubmit = async (data: any) => {
    console.log(" iw roookkkk", initialData);
    const htValue = parseFloat(data.montant_ht);
    const tvaValue = parseFloat(data.tva) || 0;
    const newTarif = {
      user: initialData?.user || "currentUserId",
      title: data.tarif,
      description: data.description,
      category: data.category,
      ht: htValue,
      tva: tvaValue,
      ttc: htValue * (1 + tvaValue / 100),
    };

    try {
      if (initialData?._id) {
        await axiosInstance.post(`/tarifs/${initialData._id}`, newTarif);
      } else {
        await axiosInstance.post("/tarifs", newTarif);
      }
      if (onSave && initialData?._id) {
        await onSave(newTarif);
      } else {
        onAdd();
      }
    } catch (error: any) {
      if (error.response?.data?.message === "Le titre du tarif existe déjà") {
        showToast("Le titre du tarif existe déjà");
      } else {
        showToast("Une erreur est survenue");
      }
    }
  };
  if (!isOpen) return null;
  return (
    <div className="popup-overlay popuptarif">
      <div className="popup-content">
        <div className="header-popup">
          <h3>{initialData ? "Modifier le tarif" : "Ajout d'un nouveau tarif"}</h3>
          <div className="popup-close-button" onClick={onClose}>
            <img src="/images/quit.svg" alt="close" />
          </div>
        </div>
        <FormProvider {...methods}>
          <SelectInput
            label="Catégorie"
            required="Catégorie invalide"
            name="category"
            placeHolder="Catégorie"
            options={[
              { value: "Administratif", label: "Administratif" },
              { value: "Autres", label: "Autres" },
              { value: "Cours", label: "Cours" },
              { value: "Pension", label: "Pension" },
            ]}
            value={initialData?.category || selectedCategory}
          />
          <div className="popup-form-group">
            <h4 style={{ fontSize: 18 }} className="currentM">
              Tarification
            </h4>
            <TextInput
              label="Tarif"
              autocomplete="off"
              name="tarif"
              required="Tarif invalide"
              value={initialData?.title || ""}
              placeHolder="Saisissez votre tarif"
              maxLength={25}
            />
            <div className="popup-form-inputs">
              <div>
                <TextInput
                  label="Montant HT"
                  autocomplete="off"
                  name="montant_ht"
                  format={formatNumber}
                  validate={(val: any) => (isNaN(val) ? "Montant HT invalide" : true)}
                  maxLength={25}
                  required="Montant HT invalide"
                  placeHolder="Montant €"
                  value={initialData?.ht?.toString() || ""}
                />
                <img src="/images/info.svg" alt="info" />
                <small className="text-muted">25 caractères maximum</small>
              </div>
              <div>
                <SelectInput
                  label="TVA"
                  name="tva"
                  placeHolder="%"
                  required="TVA (%) invalide"
                  options={[
                    {
                      label: "0 %",
                      value: "0",
                    },
                    {
                      label: "2.1 %",
                      value: "2.1",
                    },
                    {
                      label: "2.5 %",
                      value: "2.5",
                    },
                    {
                      label: "3 %",
                      value: "3",
                    },
                    {
                      label: "3.7 %",
                      value: "3.7",
                    },
                    {
                      label: "5 %",
                      value: "5",
                    },
                    {
                      label: "5.5 %",
                      value: "5.5",
                    },
                    {
                      label: "6 %",
                      value: "6",
                    },
                    {
                      label: "7.7 %",
                      value: "7.7",
                    },
                    {
                      label: "8 %",
                      value: "8",
                    },
                    {
                      label: "10 %",
                      value: "10",
                    },
                    {
                      label: "12 %",
                      value: "12",
                    },
                    {
                      label: "14 %",
                      value: "14",
                    },
                    {
                      label: "17 %",
                      value: "17",
                    },
                    {
                      label: "20 %",
                      value: "20",
                    },
                    {
                      label: "21 %",
                      value: "21",
                    },
                  ]}
                  value={tva}
                  onChange={settva}
                />
              </div>
            </div>
            <hr />
            <div className="popup-form-ttc d-flex justify-content-between">
              <h4 style={{ fontSize: 18 }} className="currentM">
                Prix TTC
              </h4>
              {!isNaN(ttc) && montantHT && tva && <h4>{formatPrice(ttc)} €</h4>}
            </div>
          </div>
          <TextInput
            label="Description"
            name="description"
            placeHolder="Description"
            maxLength={40}
            value={initialData?.description || ""}
          />
          <img src="/images/info.svg" alt="info" />
          <small className="text-muted">40 caractères maximum</small>
          <div className="popup-buttons">
            <Button className="cancel" type="reset" title="Annuler" onClick={onClose} />
            <Button
              onClick={methods.handleSubmit(onSubmit)}
              title={initialData ? "Enregistrer le tarif" : "Enregistrer le tarif"}
            />
          </div>
        </FormProvider>
      </div>
    </div>
  );
};
export default AddTarifPopup;
