import React from "react";
import Structurelayout from "../../components/structureLayout/structurelayout";
import { useAppContext } from "../../context/app.context";

function Layout(props:any){
  const {appInfos}=useAppContext();
  return <div>cgu</div>
}
export default function CGUPage() {
  return <Layout>CGUPage</Layout>;
}
