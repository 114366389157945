import { toast } from "react-toastify";

export function formatPhone(text: any) {
  const cleanedInput = text?.replace(/\D/g, "");
  if (cleanedInput) {
    const formattedText = cleanedInput.match(/.{1,2}/g).join(".");
    return formattedText;
  } else return "";
}

export function formatSiret(text: any) {
  const cleanedInput = text?.replace(/\D/g, "");
  if (cleanedInput) {
    const formatted = cleanedInput.replace(
      /(\d{3})(\d{3})(\d{3})(\d{5})?/,
      (match: any, p1: any, p2: any, p3: any, p4: any) => {
        const parts = [p1, p2, p3, p4].filter(Boolean); // Filter out undefined parts
        return parts.join(" ").trim();
      }
    );
    return formatted;
  } else return "";
}

export function formatInputPrice(text: any) {
  const cleanedInput = text?.replace(/[^0-9.]/g, "");
  if (cleanedInput) {
    const parts = cleanedInput.split(".");
    if (parts.length > 2) {
      return parts[0] + "." + parts.slice(1).join("").slice(0, 2);
    }
    return cleanedInput;
  } else {
    return "";
  }
}
export function formatNumber(text: any) {
  const cleanedInput = text?.replace(/[^0-9.]/g, "");
    return cleanedInput;
}

export function formatCode(inputText: any) {
  const cleanedInput = inputText.replace(/\D/g, "");
  const formattedText = cleanedInput.split("").join(" ");
  return formattedText;
}

export function formatPrice(val: any) {
  return (+val)?.toFixed(2)?.replace(".00", "");
}

export function getFacturePrice(val: any, user: any) {
  let sign = "";
  if (val.idRecepteur == user._id) {
    sign = val.type == "Dépense" ? "+" : "-";
  } else {
    sign = val.type == "Dépense" ? "-" : "+";
  }
  return sign + formatPrice(val.priceTTC) + " €";
}

export function getFactureTagClass(val: any,big=false) {
  return (!big?"tag ":"") + (val.status == "Réglée" ? "tagsuccess" : val.status == "Refusée" ? "tagerror" : "tagwarning");
}
export function getFactureTagTitle(val: any) {
  return val.status == "À régler" && new Date(val.echeanceDate).getTime() < Date.now() ? "En retard" : val.status;
}

export const showToast = (content: string, type?: any, position?: any) =>
  toast(content, { autoClose: 2500, type: type || "error", position, toastId: "apptoast" });

export const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const phonereg = /^(0)\d{9}$/;
export const passwordReg =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹/])[A-Za-z\d~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹/]{8,}$/;
export const sirenreg = /^\d{14}$/;
export const ibanreg =
/FR[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){2}([0-9]{2})([a-zA-Z0-9]{2}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{1})([0-9]{2})\s?/;
