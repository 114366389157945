/* eslint-disable no-empty */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import "./table.css";
import Button from "../button/button";
import "react-responsive-modal/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/app.context";
import axiosInstance from "../../config/axios";
import * as _ from "lodash";
import TextInput from "../form/inputs/text-input/text-input";
import { showToast } from "../../utils/utils";
import Modal from "../modal/modal";
import Filtermodal from "../filtermodal/filtermodal";
export default function Table(props: TableType) {
  const [opendelete, setopendelete] = useState(false);
  const [openfilter, setopenfilter] = useState(false);
  const [selectedrow, setselectedrow] = useState("");

  const [isSaving, setIsSaving] = useState(props.isSaving);
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [data, setdata] = useState(props.data);
  const { appInfos } = useAppContext();
  const emplyee = props.permission && appInfos.user.type == "Employee";
  const perms = appInfos.user?.permissions && appInfos.user?.permissions[props.permission];
  const disableAdd = emplyee && !perms?.find((elt: any) => elt == "add");
  const disableEdit = emplyee && !perms?.find((elt: any) => elt == "edit");
  const disableDelete = emplyee && !perms?.find((elt: any) => elt == "delete");
  const [searchQuery, setSearchQuery]: any = useState("");
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    props.filtername &&
      appInfos.user?.filter &&
      appInfos.user?.filters[props.filtername] &&
      appInfos.user?.filters[props.filtername]?.length
      ? [{ id: props.filtername, value: appInfos.user?.filters[props.filtername] }]
      : [],
  );
  const FilterLength =
    appInfos.user?.filters &&
    appInfos.user?.filters[props.filtername] &&
    appInfos.user?.filters[props.filtername]?.length;

  useEffect(() => {
    if (props.dataUrl) {
      (async () => {
        setIsLoading(true);
        try {
          const result = await axiosInstance.get(props.dataUrl);
          let d = result.data.data || result.data;
          if (props.mapData) {
            d = d.map((elt: any) => props?.mapData(elt));
          }
          setdata(d);
        } catch (e) {
          showToast("Une erreur est survenue");
        }
        setIsLoading(false);
      })();
    } else setdata(props.data);
  }, [props.data, props.dataUrl]);

  const navigate = useNavigate();

  function onRemove(row: any) {
    console.log(row);
    setselectedrow(row._id || row.id);
    setopendelete(true);
  }

  async function onConfirmRemove() {
    try {
      setIsSaving(true);
      if (!props.onRemove)
        try {
          await axiosInstance.delete(props.dataUrl + "/" + selectedrow);
        } catch (e) {}
      else await props.onRemove(selectedrow);
      setIsSaving(false);
      setdata(data?.filter((elt) => (elt._id || elt.id) != selectedrow));
      setopendelete(false);
      setselectedrow("");
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }

  const table = useMaterialReactTable({
    columns: props.columns,
    data: data || [],
    state: {
      isLoading: isLoading,
      isSaving: isSaving,
      globalFilter: searchQuery,
      columnFilters: columnFilters,
    },
    enableTopToolbar: false,
    localization: { ...MRT_Localization_FR, noRecordsToDisplay: props.emptytext },
    enableRowNumbers: false,
    enableStickyHeader: true,

    enableStickyFooter: true,
    icons: {
      // SortIcon:(props:any)=><img {...props} style={{ marginLeft: 10 }} alt="" src={ "/images/sortdown.svg"} />,
      // ArrowDownwardIcon: (props: any) => {
      //   const isUp = props.className.indexOf("iconDirectionAsc") != -1;
      //   return <img {...props} style={{ marginLeft: 10 }} alt="" src={isUp ? "/images/sortup.svg" : "/images/sortdown.svg"} />;
      // },
    },
    enableColumnFilters: false,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionActionsColumn: "last",
    enableToolbarInternalActions: true,
    enableRowActions: true,
    renderRowActions: props.renderRowAction
      ? props.renderRowAction
      : (p) => (
          <div style={{ display: "flex" }}>
            {!props.hideEdit && (
              <Link to={(p.row.original._id || p.row.original.id) + (props.EditPath || "")}>
                <span className="action">
                  <img src={!disableEdit ? "/images/facteye.svg" : "/images/eye.svg"} alt="modifier" />
                </span>
              </Link>
            )}
            {!props.hideDelete && (
              <span
                style={{
                  pointerEvents: disableDelete ? "none" : "auto",
                  opacity: disableDelete ? 0.2 : 1,
                }}
                className="action"
                onClick={() => onRemove(p.row.original)}
              >
                <img style={{ marginLeft: 10, cursor: "pointer" }} src="/images/remove.svg" alt="supprimer" />
              </span>
            )}
            {props.facture && (
              <a
                href={
                  process.env.REACT_APP_SERVER_URL +
                  "/api/factures/facturepdf/" +
                  (p.row.original._id || p.row.original.id) +
                  "?token=" +
                  appInfos.token
                }
              >
                <span className="action">
                  <img style={{ marginLeft: 10 }} src="/images/download.svg" alt="supprimer" />
                </span>
              </a>
            )}
          </div>
        ),
    muiTableBodyRowProps: ({ staticRowIndex, row }) => ({
      className: staticRowIndex % 2 == 0 ? "rowpair" : "rowimpair",
      onClick: props.RowClickable
        ? (event) => {
            navigate(row.original._id || row.original.id);
            console.log("msg", event, staticRowIndex);
            console.log(event, staticRowIndex);
          }
        : undefined,
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    enableColumnActions: false,
  });
  return (
    <div
      style={{
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Modal
        show={opendelete}
        title={props.deleteModalTitle || ""}
        onConfirm={onConfirmRemove}
        description={props.deleteModalDesc}
        onClose={() => setopendelete(false)}
      />
      <Filtermodal
        filtername={props.filtername}
        filterOptions={props.filterOptions}
        filtertitle={props.filtertitle}
        onClose={() => setopenfilter(false)}
        onConfirm={(data: any) => {
          setColumnFilters(data);
          setopenfilter(false);
        }}
        show={openfilter}
      />
      <div className="apptable">
        <div className="row tabletop gy-2">
          {
            <div className="col">
              <div style={{ display: "flex", flex: 1 }}>
                {props.showSearch && (
                  <div style={{ minWidth: 411 }}>
                    <TextInput
                      disabled={!data?.length}
                      onChange={setSearchQuery}
                      className="searchinput"
                      placeHolder={props.searchplaceholder}
                    />
                  </div>
                )}
                {props.showFilter && (
                  <div style={{ maxWidth: 96, marginLeft: props.showSearch ? 15 : 0 }}>
                    <Button
                      onClick={() => setopenfilter((old) => !old)}
                      className="btn-secondary filterbtn graydisabled"
                      title="Filtrer"
                      disabled={!data?.length}
                      RenderEnd={() => (FilterLength ? <span className="length-filter">{FilterLength}</span> : null)}
                    />
                  </div>
                )}
              </div>
            </div>
          }

          {!props.hideAdd && (
            <div style={{ padding: 2 }} className="col-auto ">
              <Link
                style={{ pointerEvents: disableAdd ? "none" : "auto" }}
                to={disableAdd ? (undefined as any) : "add"}
              >
                <Button className="btn-secondary addbtn" title={props.buttonTitle} />
              </Link>
              <Link className="addsm" to="add">
                <img width={32} height={32} src="/images/plus.svg" alt="modifier" />
              </Link>
            </div>
          )}
        </div>
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
}

interface TableType {
  title?: string;
  columns?: any;
  data?: any[];
  isLoading?: boolean;
  emptytext?: string;
  renderRowAction?: any;
  isSaving?: boolean;
  onRemove?: Function;
  onExport?: Function;
  onEdit?: Function;
  onAdd?: Function;
  addtitle?: string;
  hideAdd?: boolean;
  hideDelete?: boolean;
  hideEdit?: boolean;
  searchplaceholder?: string;
  showSearch?: boolean;
  showExport?: boolean;
  deleteTitle?: string;
  filtername?: any;
  filterOptions?: any;
  filtertitle?: any;
  deleteDesc?: string;
  permission?: any;
  dataUrl?: any;
  facture?: boolean;
  showFilter?: boolean;
  csvDataFn?: Function;
  mapData?: any;
  buttonTitle?: string;
  deleteModalTitle?: string;
  deleteModalDesc?: any;
  RowClickable?: boolean;
  EditPath?: string;
}
